//引入axios
// import { message } from 'antd';
import axios from 'axios'
//设置api地址
//let apiUrl = 'https://121.43.181.104:18089/api/v1'
let apiUrl ='https://journalism.lynyn.cn/api/v1/'
//标记登陆token是否过期
let isTokenExpired = false



//获取新闻列表
export async function getNewsList(orderBy, pageNo, pageSize, type, language, author){
  //1. 检查本地吃否存在token
  if (localStorage.getItem('token') === null) {
    //2. 取回最新token
    console.log('0. no token found in the local, begin fetching')
    await getNewToken();
    console.log('0.1 token fetched: ', localStorage.getItem('token'))
  } 
  console.log('1. local token found')
    //3. 尝试查询新闻列表
    console.log('2. try to fetch NewsList')
    let res = await getListData(orderBy, pageNo, pageSize, type, language, author)
    if (isTokenExpired) { //如果token过期
      console.log('2.1. token expired, begin to fetch new token')
      //4. 重新获取token
      await getNewToken();
      console.log('2.2. new token fetched: ', localStorage.getItem('token'))
      //5. 重新采用新的token查询新闻列表
      console.log('2.3. retry fetch NewsList')
      res = await getListData(orderBy, pageNo, pageSize, type, language, author);
      console.log('2.4. NewsList fetched')
    }
    return res
}

//通过API查询新闻列表Data
function getListData(orderBy, pageNo, pageSize, type, language, author) {
  //返回Promise对象
  return new Promise ((resolve, reject) => {
    let url = (
      apiUrl + "/journalism/listPageReleasedJournalism?orderBy=" +
        orderBy +
        "&orderType=DESC&pageNo=" +
        pageNo +
        "&pageSize=" +
        pageSize +
        "&type=" +
        type +
        "&language=" +
        language +
        "&author=" +
        author
    )
    // axios采用GET查询
    axios.get(
      url,
      {
        headers: {token: localStorage.getItem('token')}
      }
    ).then((res) => {
      isTokenExpired = false  //预设token过期为false
      console.log('res: ', res)
      if (res.data.msg === '请求成功') { //查询成功
        console.log("News List Data obtained: ", res)
        resolve(res)
      } else {  //token失效，应尝试重新获取
        isTokenExpired = true
        resolve()
      }
    }).catch((err) => { //查询错误
      console.log('Error occurs when fetching NewsList Data')
      reject(err)
    })
  })
}




//获取新闻详情
export async function getNewsDetail(id){
  //1. 检查本地吃否存在token
  if (localStorage.getItem('token') === null) {
    //2. 取回最新token
    console.log('0. no token found in the local, begin fetching')
    await getNewToken();
    console.log('0.1 token fetched: ', localStorage.getItem('token'))
  } 
  console.log('1. local token found')
    //3. 尝试查询新闻详情
    console.log('2. try to fetch NewsDetail')
    let res = await getNewsDetailData(id)
    if (isTokenExpired) { //如果token过期
      console.log('2.1. token expired, begin to fetch new token')
      //4. 重新获取token
      await getNewToken();
      console.log('2.2. new token fetched: ', localStorage.getItem('token'))
      //5. 重新采用新的token查询新闻列表
      console.log('2.3. retry fetch NewsDetail')
      res = await getNewsDetailData(id);
      console.log('2.4. NewsDetail fetched',res)
    }
    return res
}

//通过API查询新闻详情
function getNewsDetailData(id) {
  //返回Promise对象
  return new Promise ((resolve, reject) => {
    let url = (
      apiUrl + "/journalism/listPageJournalismDetails?id=" +
        id
    )
    // axios采用GET查询
    axios.get(
      url,
      {
        headers: {token: localStorage.getItem('token')}
      }
    ).then((res) => {
      isTokenExpired = false  //预设token过期为false
      console.log('res: ', res)
      if (res.status === 200) { //查询成功
        console.log("News detail Data obtained: ", res);
        if(res.data.httpCode === 401&& res.data.msg === '未经授权的accessToken失效或者过期'){
          isTokenExpired = true
        }
        resolve(res)
      } else {  //token失效，应尝试重新获取        
        isTokenExpired = true
        resolve()
      }
    }).catch((err) => { //查询错误
      console.log('Error occurs when fetching NewsDetail Data')
      reject(err)
    })
  })
}


//通过API获取token
function getNewToken() {
  localStorage.removeItem('token');
  //返回Promise对象
  return new Promise((resolve, reject) => {
    var param = {
      password: 123123,
      userName: "root",
    };
    // axios采用POST查询
    axios.post(
      apiUrl + "/user/login",
      param
    ).then((res) => {
      // 获取成功，将token写入localStorage
      console.log('token success: ',res)
      localStorage.setItem('token', res.data.data.token);
      resolve(res.data.data)
    }).catch((err) => {
      // 获取失败，提示错误信息
      console.log('error occurs: ', err)
      reject(err)
    })
  }) 
}


//获取浏览器参数
export function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}
//日期格式化
export function dateFormat(fmt, date) {
  let ret;
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
    }
  }
  return fmt;
}

//用法
// let date = new Date()
// dateFormat("YYYY-mm-dd HH:MM", date)
// >>> 2019-06-06 19:45`