import React from 'react'
import { Tooltip } from 'antd'
import './Partner.css'

export default function Partner(props) {
    const {title, src} = props
    let color='#3e9aff'
    return (
        <Tooltip title={title} color={color}>
                <div className='partner_item' style={{'backgroundImage': 'url('+src+')'}}></div>
        </Tooltip>
    )
}
