import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import '../../../common/javascripts/common.js'
import { getNewsDetail,getQueryString ,dateFormat} from '../../../common/javascripts/common.js'
import './NewsDetail.css'


export default function NewsDetail(props) {

  const [newsDetailInfo, setNewsDetailInfo] = useState({});  //新闻详情赋值
 

  const getNewsDetailMethods = (id) =>{
    getNewsDetail(id).then(function(res){
      console.log(res)
      if(res.data.httpCode === 200){
        setNewsDetailInfo(res.data.data)
      }else{
        message.error(res.data.msg)
      }
     // setNewsDetailInfo(res.data.data)
    })
  }
  useEffect(() => {
    // 变更当前查询状态为false，即按钮恢复可用(查询已结束)
    getNewsDetailMethods(getQueryString('id'))
  },[])
  // 查询函数，从远程服务器异步获取公司新闻数据

  // 查询更多数据函数，工作方式为页号增加1，state变更成功后，触发监听的useEffect，并进入查询程序
  // 定义工具函数，用于格式化输出新闻卡片组，内容输出到card组件
  //tab组件的items参数内容格式化，即分别定义“公司新闻”+“行业资讯”的标签页，及其包含的card新闻内容组
  //渲染组件
  return(
    <div className="news_detail_container">
   
         
        <div className="newsdetail">
          {/* <FormattedMessage id="pages.audit.title" defaultMessage="Advanced layout" />{' '}  */}
          <div className="title-box">
            <h3>{newsDetailInfo.title}</h3>
            <p>作者：{newsDetailInfo.author}</p>

            <p>创建时间：{dateFormat('YYYY-mm-dd', new Date(newsDetailInfo.gmtCreate))}</p>
            <p>摘要：{newsDetailInfo.digest}</p>
          </div>
          <div
            className="news-content"
            dangerouslySetInnerHTML={{ __html: newsDetailInfo.content }}
          ></div>
        </div>
      
    </div>
  )
}
