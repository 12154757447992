// eslint-disable-next-line
import React, { Component, useState, useEffect } from 'react'
import { Tabs, Card, Button } from 'antd'
import '../../../common/javascripts/common.js'
import { getNewsList } from '../../../common/javascripts/common.js'
import './NewsList.css'


export default function NewsList(props) {
  const lang = props.lang === 0 ? 1 : 2;//后台接口需要的参数中文为1，英文为2，故此处重新赋值
  const deviceType = props.deviceType;
  console.log(deviceType)
  // 初始化
  const [newsCompany, setCompany] = useState([]);//公司新闻数据用state
  const [newsIndustry, setIndustry] = useState([]);//行业资讯数据用state
  const [pageCompany, setPageCom] = useState(1);//公司新闻当前查询页数
  const [pageIndustry, setPageInd] = useState(1);//行业资讯当前查询页数
  const [loadingCompany, setLoadingCom] = useState([true]);//标记当前公司新闻查询是否正在进行，true时，按钮为不可使用状态
  const [loadingIndustry, setLoadingInd] = useState([true]);//标记当前行业资讯查询是否正在进行，true时，按钮为不可使用状态
  const [loadTarget, setTarget] = useState(3);  //特殊标记状态，表示需要获取的目标数据，1是公司新闻，2是行业资讯，3是全部
  const { Meta } = Card; //Card组件子组件声明
  
  // 监听state：用户请求数据页号，即只有当用户请求的页数发生变化的时候，程序才可以执行新数据查询
  useEffect(() => {
    // 分别查询公司新闻及行业资讯
    if(loadTarget !== 2) {loadCompany()};
    if(loadTarget !== 1) {loadIndustry()};
    // eslint-disable-next-line
  },[pageCompany, pageIndustry])
  // 监听state: 新闻数据及查询状态，如果数据有更新则重新渲染页面（即分别重新渲染数据或者装载更多的状态按钮恢复
  useEffect(() => {
    // 变更当前查询状态为false，即按钮恢复可用(查询已结束)
    setLoadingCom(false)
    setLoadingInd(false)
  },[newsCompany, newsIndustry])
  // 查询函数，从远程服务器异步获取公司新闻数据
  function loadCompany(){
    getNewsList('sort',pageCompany,8,2,lang,'LYNYN').then(function(res){
      setCompany(newsCompany.concat(res.data.data.journalismAuditList))
    })
  }
  // 查询函数，从远程服务器异步获取行业资讯数据
  function loadIndustry(){
    getNewsList('sort',pageIndustry,8,2,lang,'PETRO').then(function(res){
      setIndustry(newsIndustry.concat(res.data.data.journalismAuditList))
    })
  }
  // 查询更多数据函数，工作方式为页号增加1，state变更成功后，触发监听的useEffect，并进入查询程序
  const loadMoreCom = () => {
    setPageCom(preState => preState + 1)
    setLoadingCom(true)
    setTarget(1)
  }
  const loadMoreInd = () => {
    setPageInd(preState => preState + 1)
    setLoadingInd(true)
    setTarget(2)
  }
  // 定义工具函数，用于格式化输出新闻卡片组，内容输出到card组件
  let createCards = (newsType) => {
    // 判断当前是否是页面初始加载渲染
    if(newsType===[]) {
      return "loading"
    } else {
      // 这里是state首次变更，也就是取得数据后触发的组件渲染刷新
      return (
        // 根据取得的数据循环遍历生成卡片
        newsType.map((news,i) => {
          return (
              <div className='news_cards' key={'news_' + news.id}>
                <Card hoverable className='news_cards_item' cover={<img alt={news.title} src={news.cover}/>}  onClick={()=>toDetail(news.id)}>
                  <Meta title={news.title} description={news.author} key={'1'}/>
                </Card>
              </div>
          )
        }) 
      )
    };
  }

  const toDetail = (id)=>{
    // eslint-disable-next-line
    window.open(lang===1 ? '/news_detail_cn' + '?lang=' + lang + '&id=' + id : '/news_detail_en' + '?lang=' + lang + '&id=' + id);
  }
  //tab组件的items参数内容格式化，即分别定义“公司新闻”+“行业资讯”的标签页，及其包含的card新闻内容组
  let tabItems = [
    {
      label: lang === 1 ? '公司新闻' : 'Company News', 
      key: '1', 
      children: 
        <div className='news_tabItem'>
          <div className='news_cardsWrapper'>
            {createCards(newsCompany)} 
          </div>
          <div className='news_loadMore'><Button className='news_loadButton' onClick={loadMoreCom} disabled={loadingCompany? true : false}>{lang === 1 ? '加载更多' : 'Load More'}</Button></div>
        </div>
    },
    {
      label: lang === 1 ? '行业资讯' : 'Industry Knowledge', 
      key: '2', 
      children: 
        <div className='news_tabItem'>
          <div className='news_cardsWrapper'>
            {createCards(newsIndustry)} 
          </div>
          <div className='news_loadMore'><Button className='news_loadButton' onClick={loadMoreInd} disabled={loadingIndustry? true : false}>{lang === 1 ? '加载更多' : 'Load More'}</Button></div>
        </div>
    }
  ]
  //渲染组件
  return(
    <div>
          <Tabs defaultActiveKey='1' centered items={tabItems} size='large' />
    </div>
  )
}
