import React from 'react'
import Partner from './Partner'
import './Partner.css'

//本组件为单个子组件Partner的集合
export default function Partners() {
    return (
        <div className='partners_container'>
            <div className='sec_title'>
                <span>合作伙伴</span>
                <span>PARTNERSHIPS</span>
            </div>
            <div className='partners_item_box'>
                <Partner title='日本三井化学' src='/images/partners/partner_01.jpg' />
                <Partner title='上海中石化三井' src='/images/partners/partner_02.jpg' />
                <Partner title='利华益维远' src='/images/partners/partner_03.jpg' />
                <Partner title='科思创' src='/images/partners/partner_07.jpg' />
                <Partner title='泰国国家石油' src='/images/partners/partner_16.jpg' />
                <Partner title='宁波大风' src='/images/partners/partner_04.jpg' />
                <Partner title='中石化燕山' src='/images/partners/partner_09.jpg' />
                <Partner title='台塑集团' src='/images/partners/partner_13.jpg' />
                <Partner title='LG化学' src='/images/partners/partner_14.jpg' />
                <Partner title='三菱瓦斯' src='/images/partners/partner_15.jpg' />
                <Partner title='锦湖石化' src='/images/partners/partner_17.jpg' />
                <Partner title='浙江石化' src='/images/partners/partner_18.jpg' />
                <Partner title='三菱化学' src='/images/partners/partner_05.jpg' />
                <Partner title='南通星辰' src='/images/partners/partner_08.jpg' />
                <Partner title='盛通聚源' src='/images/partners/partner_10.jpg' />
                <Partner title='万华化学' src='/images/partners/partner_11.jpg' />
                <Partner title='中蓝国塑' src='/images/partners/partner_12.jpg' />
                <Partner title='长春化工' src='/images/partners/partner_06.jpg' />

            </div>
        </div>

    )
}
