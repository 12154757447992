//创建外壳组件App

import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Header from './components/Header/Header'
import NewsList from './components/News/NewsList/NewsList'
import NewsDetail from './components/News/NewsDetail/NewsDetail'
import Partners from './components/Partner/Partners'
import Contacts from './components/Contacts/Contacts'
import Footer from './components/Footer/Footer'
import Products from './components/Products/Products'
import Cultures from './components/Cultures/Cultures'
import Homepage from './components/Homepage/Homepage'
import './App.css'
import './common/javascripts/common'

let lang;
let url = window.location.pathname;
lang = url.endsWith('_en') ? 1 : 0;
// 声明变量，用来存放语言切换时候Route模块需要切换的目标路由
// 页面载入时即通过当前页面url判断并生成toUrl目标路由
let toUrl;
if (url === '/') {
    toUrl = './home_en';
} else if (url.endsWith('_cn')) {
    toUrl = '.' + url.replace(/_cn/i, '_en');
} else if (url.endsWith('_en')) {
    toUrl = '.' + url.replace(/_en/i, '_cn')
}
let deviceType = isMobileDevice()?0:1;
console.log(deviceType);
//工具函数 判断设备是pc还是移动
function isMobileDevice() {
    return /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
export default function App(){
    
    return (
        <div className="root_container">
            <Header lang={lang} toUrl={toUrl}/>
            <div className='app_container'>
                <Routes>
                    <Route path='/' element={<Homepage lang={0} deviceType={deviceType} />} />
                    <Route path='/home_cn' element={<Homepage lang={0} deviceType={deviceType}  />} />
                    <Route path='/home_en' element={<Homepage lang={1}  deviceType={deviceType} />} />
                    <Route path='/cultures_cn' element={<Cultures lang={0} deviceType={deviceType}  />} />
                    <Route path='/cultures_en' element={<Cultures lang={1}  deviceType={deviceType} />} />
                    <Route path='/news_cn' element={<NewsList lang={0}  deviceType={deviceType} />} />
                    <Route path='/news_en' element={<NewsList lang={1}  deviceType={deviceType} />} />
                    <Route path='/news_detail_cn' element={<NewsDetail lang={0}  deviceType={deviceType} />} />
                    <Route path='/news_detail_en' element={<NewsDetail lang={1}  deviceType={deviceType} />} />
                    <Route path='/products_cn' element={<Products lang={0}  deviceType={deviceType} />} />
                    <Route path='/products_en' element={<Products lang={1}  deviceType={deviceType} />} />
                    <Route path='/contacts_cn' element={<Contacts lang={0}  deviceType={deviceType} />}/>
                    <Route path='/contacts_en' element={<Contacts lang={1}  deviceType={deviceType} />}/>
                </Routes>
            </div>
            <Partners />
            <Footer />

        </div>
    )
}