import React from 'react'
import ManagerCard from './ManagerCard'
import './Contacts.css'

export default function ManagerCards(props) {
  const lang = props.lang
  return (
    <div className='contacts_cardsContainer'>
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt_yangjian.png'} 
          textName={['杨键','Yang Jian']}
          textLocation={['上海','Shanghai']}
          textTitle={['产品主管','Chief Product Manager']}
          textDivision={['双酚A产品部','Bisphenol-A Product Dept.']}
          textProduct={['BPA','PC','PPG']}
          textTel={'186 1660 4849'}
          textMail={'yangjian@lynyn.cn'}
          key='001'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt_renzhechai.png'} 
          textName={['任哲','Ren Zhe']}
          textLocation={['台州','Taizhou']}
          textTitle={['总经理','General Manager']}
          textDivision={['塑胶产品部','Plastic Materials Dept.']}
          textProduct={['PC','PETG','PC/ABS']}
          textTel={'136 1188 2922'}
          textMail={'renzhechai@lynyn.cn'}
          key='002'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt_xudingyi.png'} 
          textName={['徐定一','Kevin Xu']}
          textLocation={['爱尔兰','Ireland']}
          textTitle={['总经理','General Manager']}
          textDivision={['都柏林/爱尔兰 办事处','Dublin/Ireland Branch']}
          textProduct={['ER','PC','PPG']}
          textTel={'+353 87 1415690'}
          textMail={'kevin@lynyn.cn'}
          key='003'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt_jijialing.png'} 
          textName={['纪家玲','Ji Jialing']}
          textLocation={['上海','Shanghai']}
          textTitle={['产品经理','Product Manager']}
          textDivision={['液体产品部','Liquid Materials Dept.']}
          textProduct={['PH','PPG']}
          textTel={'180 4991 1220'}
          textMail={'jijialing@lynyn.cn'}
          key='005'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt_huangyan.png'} 
          textName={['黄燕','Huang Yan']}
          textLocation={['上海','Shanghai']}
          textTitle={['商务经理','Commercial Manager']}
          textDivision={['总务管理部','General Affair Managing Dept.']}
          textProduct={['售后投诉']}
          textTel={'137 6492 3853'}
          textMail={'huangyan@lynyn.cn'}
          key='006'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt_jiayujia.png'} 
          textName={['郏宇佳','Jia Yujia']}
          textLocation={['台州','Taizhou']}
          textTitle={['销售经理','Sales Manager']}
          textDivision={['塑胶产品销售部 台州站','Plastic Materials Sales, Taizhou']}
          textProduct={['PC','PETG','PC/ABS']}
          textTel={'157 2696 5054'}
          textMail={'jiayujia@lynyn.cn'}
          key='007'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt_xuyingdong.png'} 
          textName={['徐英东','Xu Yingdong']}
          textLocation={['常州','Changzhou']}
          textTitle={['销售经理','Sales Manager']}
          textDivision={['塑胶产品销售部 常州站','Plastic Materials Sales, Changzhou']}
          textProduct={['PC','PETG','PC/ABS']}
          textTel={'150 4192 8080'}
          textMail={'xuyingdong@lynyn.cn'}
          key='008'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt_hanshukai.png'} 
          textName={['韩舒凯','Han Shukai']}
          textLocation={['东莞','Dongguan']}
          textTitle={['销售经理','Sales Manager']}
          textDivision={['塑胶产品销售部 东莞站','Plastic Materials Sales, Dongguan']}
          textProduct={['PC','PETG','PC/ABS']}
          textTel={'135 5660 1372'}
          textMail={'hanshukai@lynyn.cn'}
          key='009'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt_huangronghua.png'} 
          textName={['黄荣华','H. Ronghua']}
          textLocation={['余姚','Yuyao']}
          textTitle={['销售经理','Sales Manager']}
          textDivision={['塑胶产品销售部 余姚站','Plastic Materials Sales, Yuyao']}
          textProduct={['PC','PETG','PC/ABS']}
          textTel={'139 6865 0357'}
          textMail={'-'}
          key='010'
        />
        
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt_wangwendi.png'} 
          textName={['王文迪','Wendy Wang']}
          textLocation={['爱尔兰','Ireland']}
          textTitle={['销售经理','Sales Manager']}
          textDivision={['都柏林 办事处','Dublin/Ireland Branch']}
          textProduct={['ER','PC','PPG']}
          textTel={'+353 87 1415690'}
          textMail={'wendy@lynyn.cn'}
          key='011'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt00.png'} 
          textName={['LYNYN','LYNYN']}
          textLocation={['','']}
          textTitle={['','']}
          textDivision={['','']}
          textProduct={['']}
          textTel={''}
          textMail={''}
          key='012'
        />
        {/* <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt00.png'} 
          textName={['LYNYN','LYNYN']}
          textLocation={['','']}
          textTitle={['','']}
          textDivision={['','']}
          textProduct={['']}
          textTel={''}
          textMail={''}
          key='013'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt00.png'} 
          textName={['LYNYN','LYNYN']}
          textLocation={['','']}
          textTitle={['','']}
          textDivision={['','']}
          textProduct={['']}
          textTel={''}
          textMail={''}
          key='013'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt00.png'} 
          textName={['LYNYN','LYNYN']}
          textLocation={['','']}
          textTitle={['','']}
          textDivision={['','']}
          textProduct={['']}
          textTel={''}
          textMail={''}
          key='014'
        />
        <ManagerCard 
          lang={lang}
          avtSrc={'/images/contacts/avt00.png'} 
          textName={['LYNYN','LYNYN']}
          textLocation={['','']}
          textTitle={['','']}
          textDivision={['','']}
          textProduct={['']}
          textTel={''}
          textMail={''}
          key='015'
        /> */}
        
    </div>
  )
}
