import React from 'react'
import { Image } from 'antd';
import './Cultures.css'

// 变量初始化存放文字描述内容
const contents = {
  culture: {
    title: ['企业文化','Culture'],
    content: [
      '饮水思源，不忘初心，回报社会，公司坚持投身于社会公益事业。通过与有关官方公益组织、单位的沟通与合作，以公司自身的绵薄之力，力求精准定位受助对象个体或群体，施以资助或物资援助，帮助对象克服短期困境，走向生活的阳光灿烂。',
      'We are in mission of contributing the society from where we build up our business and experience great joys of our life. Regularly we collaborate with charity organizations, aimed to aid the most needed individual or groups of people, help them to walk out the temporary shadow and enjoy the life.'
    ]
  },
  responsibility: {
    title: ['社会责任','Responsibility'],
    content: [
      '公司每年组织举办各种文娱活动，以期促进同事之间的交流与互动，增进感情，加深理解，在工作之余保持身心健康、精神面貌积极向上。活动内容不限形式，包括如团建旅行、聚会聚餐、运动健身等各种项目。',
      'Our company family holds various entertainment or cultural events every year. Through such activities, we enhance our mutual trust and communications deepen understanding about each others. The activities are including but not limited to such as company outing, parties and sports.'
    ]
  }
}

export default function Cultures(props) {
  const { lang } = props;
  return (
    <div className='cultures_container'>
      {/* 标题部分 企业文化、社会责任*/}
      <div className='sec_title'>
        <span>
          企业文化/社会责任
        </span>
        <span>
          Culture & Responsibility
        </span>
      </div>
      {/* 企业文化与社会责任卡片展示部分 */}
      <div className='cultures_cardContainer'>
        {/* 企业文化 */}
        <div className='cardWrapper'>
          <div className='cardCover'><img src='./images/cultures/culture.jpg'  alt=''></img></div>
          <div className='cardContent'>
            <div>{lang === 0 ? contents.culture.title[0] : contents.culture.title[1]}</div>
            <div style={lang === 1 ? {'fontSize' : '12px'} : {}}>{lang === 0 ? contents.responsibility.content[0] : contents.responsibility.content[1]}</div>
          </div>
        </div>
        {/* 社会责任 */}
        <div className='cardWrapper'>
          <div className='cardCover'><img src='./images/cultures/responsibility.png'  alt=''></img></div>
          <div className='cardContent'>
            <div>{lang === 0 ? contents.responsibility.title[0] : contents.responsibility.title[1]}</div>
           
            <div style={lang === 1 ? {'fontSize' : '12px'} : {}}>{lang === 0 ? contents.culture.content[0] : contents.culture.content[1]}</div>
          </div>
        </div>
      </div>
      {/* 标题部分 企业荣誉展示*/}
      <div className='sec_title'>
        <span>
          企业荣誉
        </span><br />
        <span>
          Honor Board
        </span>
      </div>
      {/* 企业荣誉展示部分 */}
      <div className='cultures_boardContainer'>
        <div><Image width={250} src='/images/cultures/card01.jpg'/></div>
        <div><Image width={250} src='/images/cultures/card02.jpg'/></div>
        <div><Image width={250} src='/images/cultures/card03.jpg'/></div>
        <div><Image width={250} src='/images/cultures/card04.jpg'/></div>
        {/* <div><Image width={250} src='/images/cultures/card05.jpg'/></div> */}
        <div><Image width={250} src='/images/cultures/card06.jpg'/></div>
        <div><Image width={250} src='/images/cultures/card07.jpg'/></div>
        <div><Image width={250} src='/images/cultures/card08.jpg'/></div>
        <div><Image width={250} src='/images/cultures/card09.jpg'/></div>
      </div>
    </div>
  )
}
