import React from 'react'
import './Products.css'

export default function Products(props) {
  const { lang } = props
  return (
    <div className='products_container'>
        <div className='products_mapping'>
            <div className={lang === 0 ? 'products_hexBack_cn' : 'products_hexBack_en'}>

            </div>
        </div>
        <div className='sec_title'>
          <span>产品与应用</span>
          <span>Products & Applications</span>
        </div>
        <div className='products_imgContainer'>
          <div className='products_imgPrdct'>
            <div><img src='/images/products/item01.png' alt=''></img></div>
            <div className='products_imgTitle'>PC</div>  
          </div>
          <div className='products_imgPrdct'>
            <div><img src='/images/products/item02.png' alt=''></img></div>
            <div className='products_imgTitle'>BPA</div>  
          </div>
          <div className='products_imgPrdct'>
            <div><img src='/images/products/item03.png' alt=''></img></div>
            <div className='products_imgTitle'>LER</div>  
          </div>
          <div><img src='/images/products/item04.png' alt=''></img></div>
          <div><img src='/images/products/item05.png' alt=''></img></div>
          <div><img src='/images/products/item06.png' alt=''></img></div>
          <div><img src='/images/products/item08.png' alt=''></img></div>
          <div><img src='/images/products/item09.png' alt=''></img></div>
          <div><img src='/images/products/item10.png' alt=''></img></div>
          <div><img src='/images/products/item11.png' alt=''></img></div>
          <div><img src='/images/products/item12.png' alt=''></img></div>
        </div>
    </div>
  )
}
