import React from 'react'
import './Footer.css'

export default function Footer(props) {
  const {deviceType} = props
  return (
    <div className='footer_container'>
        <img className='footer_logo' src='images/logo.png' alt=''></img>

        <div className='footer_contact'>
          <span>电话：021-6890 9007 {deviceType === 1?'|':''}</span>
          <span>邮箱：public@lynyn.cn {deviceType === 1?'|':''} </span>
          <span>地址：上海市浦东新区祖冲之路2277弄1号811室</span>
        </div>
        <div>
          <span className='footer_copyright'>Copyright @ 2021版权所有（上海林炎工贸有限公司）</span>
        </div>
        <div>
          <a href='http://beian.miit.gov.cn/'>沪ICP备17015073号|工信部网站</a>
        </div>
    </div>
  )
}
