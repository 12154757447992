import React from 'react'
import {
    HomeOutlined
  } from '@ant-design/icons';
import {Tag} from 'antd'
export default function ManagerCard(props) {
    const {lang, avtSrc, textName, textLocation, textTitle, textDivision, textProduct, textTel, textMail} = props
    return (
        // 卡片容器
        <div className='contacts_cardContainer'>
            {/* 姓名 */}
            <div className='contacts_name' style={lang === 1? {'fontSize':'17px','fontWeight':'600'} : {'fontSize':'22px','fontWeight':'300'} }>
                <span>{lang === 0? textName[0] : textName[1]}</span>
            </div>
            {/* 驻地 */}
            <div className='contacts_location' style={lang === 1? {'fontSize':'12px'} : {'fontSize':'16px'} }>
                <span><HomeOutlined style={{'color':'#7d7d7d'}}/> : {lang === 0? textLocation[0] : textLocation[1]}</span>
            </div>
            {/* 头像 */}
            <img className='contacts_avatar' src={avtSrc} alt=''></img>
            {/* 明细信息 */}
            <div className='contacts_detail'>
                {/* 职位 */}
                <div className='contacts_title' style={lang === 1? {'fontSize':'16px'} : {'fontSize':'20px'} }>
                    <span>{lang === 0? textTitle[0] : textTitle[1]}</span>
                </div>
                {/* 部门 */}
                <div className='contacts_title' style={lang === 1? {'fontSize':'12px'} : {'fontSize':'14px'} }>
                    <span>{lang === 0? textDivision[0] : textDivision[1]}</span>
                </div>
                {/* 产品标签 */}
                <div className='contacts_title'>
                    {textProduct.map((product, i) => {
                        return (
                            // <span className='contacts_product' key={i}>{product}</span>
                            <Tag className='contacts_product' key={i} color="blue">{product}</Tag>

                        )
                    })}
                </div>
                <br />
                {/* 联系方式 */}
                <div style={lang === 1? {'fontSize':'12px'} : {'fontSize':'14px'}}><span>{'TEL: ' + textTel}</span></div>
                <div style={lang === 1? {'fontSize':'12px'} : {'fontSize':'14px'}}><span>{'ADD: ' + textMail}</span></div>
            </div>
        </div>
    )
}
