import React from 'react'
import { Map, Marker } from 'react-amap';

export default function LocationMap(props) {
    let {lang, location} = props;
  return (
    <div className='contacts_locationContainer'>
        {/* 地图 */}
        <div>
            <div className='contacts_mapContainer'>
                <Map zoom={18} center={location.center} viewMode={'3D'} amapkey='ed09e805dd2b632a8fc17d098436250a'>
                    <Marker position={location.position}></Marker>
                </Map>
            </div>
            <div className='contacts_mapAddress'>
                <span>
                    {lang === 0 ? location.addCN : location.addEN}
                </span>
            </div>
        </div>
        {/* 照片 */}
        <div className="location_company_image">
            <img className='contacts_img' src={location.picURL1} alt=''></img>
            <img className='contacts_img' src={location.picURL2} alt=''></img>
            <img className='contacts_img' src={location.picURL3} alt=''></img>
        </div>
    </div>
  )
}
