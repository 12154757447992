import React from 'react'
// import {Routes, Route} from 'react-router-dom'
import { Collapse } from 'antd'
import ManagerCards from './ManagerCards'
import LocationMap from './LocationMap'
import './Contacts.css'

const locations = {
  location01: {
    center: [121.626573,31.21207], //定位中心点
    position: [121.626573,31.21207], //定位点
    addCN: '上海总部：上海市浦东新区祖冲之路2277弄1号世和商务中心811室', //中文地址
    addEN: 'Shanghai Head： Room 811 Shihe Business Center, Building 1, No. 2277 Zuchongzhi Road, Pudong, Shanghai', //英文地址
    picURL1: '/images/contacts/shihe01.jpg', //展示照片1
    picURL2: '/images/contacts/shihe02.jpg', //展示照片2
    picURL3: '/images/contacts/shihe03.jpg' //展示照片3
  },
  location02: {
    center: [121.39,28.59], //定位中心点
    position: [121.39,28.59], //定位点
    addCN: '台州办事处：浙江省台州市路桥区塑料化工市场1004号', //中文地址
    addEN: 'Taizhou Branch： No. 1004 Luqiao Plastic & Chemical Market, Taizhou, Zhejiang', //英文地址
    picURL1: '/images/contacts/taizhou01.jpg', //展示照片1
    picURL2: '/images/contacts/taizhou02.jpg', //展示照片2
    picURL3: '/images/contacts/taizhou03.jpg' //展示照片3
  }
}

export default function Contacts(props) {
  const lang = props.lang; //声明当前语言选择，0中文，1英文
  const {Panel} = Collapse;
  
  return (
    <div className='contacts_container'>
      <Collapse defaultActiveKey={'1'}
        style={
          {
            'backgroundColor' : 'var(--lynyn-lightBlue)',
            'fontSize' : '20px'
          }

        }
      >
        <Panel header={lang === 0 ? '业务联系人' : 'Business Contacts'} key='1' >
          <ManagerCards lang={lang} />
        </Panel>
      </Collapse>
      <br />
      <LocationMap lang={lang} location={locations.location01}/>
      <br />
      <LocationMap lang={lang} location={locations.location02}/>
    </div>
  )
}
