import React from "react";
import "./Homepage.css";

const contents = {
  briefing: {
    title: ["石油化工产品分销服务商", "Petrochemical Products Distributor"],
    content: [
      {
        p1: "林炎于2009年在上海成立，自设立以来长期致力于石化产品在中国市场的分销业务。",
        p2: "我们的业务以本地化及专业化为特点。团队长期扎根于中国石化市场，广泛联络国内外供应商，与各类型客户保持长期、友好合作关系，为上下游分别提供价值服务，为中国相关产业发展做出力所能及的贡献。",
      },
      {
        p1: "Lynyn was founded in 2009 in Shanghai. From then, we had been dedicating in contributing to the petrochemical distribution business in China.",
        p2: "By localized and professional manner, we have been rooted in the China petrochemical market. It was wildly connected with all kinds of suppliers domestic or international. With various customers, we have been keep the smooth cooperation relationships",
      },
    ],
  },
  feature_lt: {
    title: [
      { p1: "信任", p2: "专业" },
      { p1: "Mutual", p2: "Trust" },
    ],
    content: [
      "利用10年来的行业深度参与经验，林炎与行业相关上下游及渠道单位保持了密切并且互信的合作关系，深度掌握行业一手信息，对市场具备最深刻的理解；",
      "With 10+ years of experience in cooperating with the most valued manufactuers and players in the industry, we built deep trust and unique channel to access the core knowledges about the development of the past and future of the industry.",
    ],
  },
  feature_rt: {
    title: [
      { p1: "薄利", p2: "多销" },
      { p1: "Profit from", p2: "Volume" },
    ],
    content: [
      "以朴素分销作为贸易理念，林炎不断扩大核心产品的市场经销份额，提高分配效率并降低成本，以行业整理利益最大化为工作目标；",
      "With mission of distribution, LYNYN keeps in enlarging the handling volume and market share of her core products. By deploying the materials more efficiently to its needs in large parcels, she is effectively reducing the general cost for running the market.",
    ],
  },
  feature_lb: {
    title: [
      { p1: "创新", p2: "分销" },
      { p1: "Tradition", p2: "Innovation" },
    ],
    content: [
      "非同于一般产品贸易商舍本求末，迷失于金融创新或者过度投机，林炎的业务恪守最朴素的分销职能，又充分引入创新型管理方法与模型，与时俱进，为上下游企业提供有价值的业务管理服务；",
      "Instead of getting lost in over financialization or speculation by taking use of her product and market, LYNYN standy by as a simpliest distributor, missioned to deliver the best goods to valued clients. To serve this tradition, LYNYN innovatively applied management with models and methods by science and technology.",
    ],
  },
  feature_rb: {
    title: [
      { p1: "协同", p2: "共进" },
      { p1: "Professional", p2: "Synergy" },
    ],
    content: [
      "林炎不拘泥于单一产品的优势增值，我们不断开拓进取，上下游完成产品链贸易的延展，横向开拓运输、仓储及信息等立体增值服务；",
      "LYNYN is not eased at the matured products and markets, instead, such advance is taken use of in exploring up or downward in the industry value chain. Transportation, storage & market knowledge services are being developed and provided as added value to synergize with the products altogether.",
    ],
  },
};

export default function Homepage(props) {
  const { lang, deviceType } = props;
  return (
    <div className="home_container">
      <div className="sec_title">
        <span>公司简介</span>
        <span>Company Briefing</span>
      </div>
      <div>
        <div className="brief_img">
          <img src="./images/briefing.jpg" alt="briefing" />
        </div>
        <div className="briefing">
          <h3>
            {lang === 0
              ? contents.briefing.title[0]
              : contents.briefing.title[1]}
          </h3>
          <span>
            {lang === 0
              ? contents.briefing.content[0].p1
              : contents.briefing.content[1].p1}
          </span>
          <br />
          <span>
            {lang === 0
              ? contents.briefing.content[0].p2
              : contents.briefing.content[1].p2}
          </span>
        </div>
      </div>
      <br />
      {/* 经营理念标题 */}
      <div className="sec_title">
        <span>经营理念</span>
        <span>Company Value</span>
      </div>
      {deviceType === 1 && (
        <div className="featureContainer">
          <div>
            <div className="word">
              <p style={lang === 1 ? { fontSize: "16px" } : {}}>
                {lang === 0
                  ? contents.feature_lt.content[0]
                  : contents.feature_lt.content[1]}
              </p>
            </div>
            <div className="lt">
              <div>
                <span style={lang === 1 ? { fontSize: "25px" } : {}}>
                  {lang === 0
                    ? contents.feature_lt.title[0].p1
                    : contents.feature_lt.title[1].p1}
                  <br />
                  {lang === 0
                    ? contents.feature_lt.title[0].p2
                    : contents.feature_lt.title[1].p2}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="rt">
              <div>
                <span style={lang === 1 ? { fontSize: "25px" } : {}}>
                  {lang === 0
                    ? contents.feature_rt.title[0].p1
                    : contents.feature_rt.title[1].p1}
                  <br />
                  {lang === 0
                    ? contents.feature_rt.title[0].p2
                    : contents.feature_rt.title[1].p2}
                </span>
              </div>
            </div>
            <div className="word">
              <p style={lang === 1 ? { fontSize: "16px" } : {}}>
                {lang === 0
                  ? contents.feature_rt.content[0]
                  : contents.feature_rt.content[1]}
              </p>
            </div>
          </div>
          <div>
            <div className="word">
              <p style={lang === 1 ? { fontSize: "16px" } : {}}>
                {lang === 0
                  ? contents.feature_lb.content[0]
                  : contents.feature_lb.content[1]}
              </p>
            </div>
            <div className="lb">
              <div>
                <span style={lang === 1 ? { fontSize: "25px" } : {}}>
                  {lang === 0
                    ? contents.feature_lb.title[0].p1
                    : contents.feature_lb.title[1].p1}
                  <br />
                  {lang === 0
                    ? contents.feature_lb.title[0].p2
                    : contents.feature_lb.title[1].p2}
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className="rb">
              <div>
                <span style={lang === 1 ? { fontSize: "25px" } : {}}>
                  {lang === 0
                    ? contents.feature_rb.title[0].p1
                    : contents.feature_rb.title[1].p1}
                  <br />
                  {lang === 0
                    ? contents.feature_rb.title[0].p2
                    : contents.feature_rb.title[1].p2}
                </span>
              </div>
            </div>
            <div className="word">
              <p style={lang === 1 ? { fontSize: "16px" } : {}}>
                {lang === 0
                  ? contents.feature_rb.content[0]
                  : contents.feature_rb.content[1]}
              </p>
            </div>
          </div>
        </div>
      )}
      {deviceType === 0 && (
        <div className="featureContainer">
          <div className="feature_item">
            <div className="feature_item_title">
              {lang === 0
                ? contents.feature_lt.title[0].p1
                : contents.feature_lt.title[1].p1}

              {lang === 0
                ? contents.feature_lt.title[0].p2
                : contents.feature_lt.title[1].p2}
            </div>
            <div className="feature_item_description">
              <div className="feature_item_description_img">
                <img src="./images/feature01_phone.png" alt="feature01_phone" />
              </div>
              <div className="feature_item_description_word">
                {lang === 0
                  ? contents.feature_lt.content[0]
                  : contents.feature_lt.content[1]}
              </div>
            </div>
          </div>

          <div className="feature_item">
            <div className="feature_item_title">
              {lang === 0
                ? contents.feature_rt.title[0].p1
                : contents.feature_rt.title[1].p1}
             
              {lang === 0
                ? contents.feature_rt.title[0].p2
                : contents.feature_rt.title[1].p2}
            </div>
            <div className="feature_item_description">
              <div className="feature_item_description_img">
                <img src="./images/feature02_phone.png" alt="feature02_phone" />
              </div>
              <div className="feature_item_description_word">
                {lang === 0
                  ? contents.feature_rt.content[0]
                  : contents.feature_rt.content[1]}
              </div>
            </div>
          </div>

          <div className="feature_item">
            <div className="feature_item_title">
              {lang === 0
                ? contents.feature_lb.title[0].p1
                : contents.feature_lb.title[1].p1}
             
              {lang === 0
                ? contents.feature_lb.title[0].p2
                : contents.feature_lb.title[1].p2}
            </div>
            <div className="feature_item_description">
              <div className="feature_item_description_img">
                <img src="./images/feature03_phone.png" alt="feature03_phone" />
              </div>
              <div className="feature_item_description_word">
                {lang === 0
                  ? contents.feature_lb.content[0]
                  : contents.feature_lb.content[1]}
              </div>
            </div>
          </div>
          <div className="feature_item">
            <div className="feature_item_title">
              {lang === 0
                ? contents.feature_rb.title[0].p1
                : contents.feature_rb.title[1].p1}
    
              {lang === 0
                ? contents.feature_rb.title[0].p2
                : contents.feature_rb.title[1].p2}
            </div>
            <div className="feature_item_description">
              <div className="feature_item_description_img">
                <img src="./images/feature04_phone.png" alt="feature04_phone" />
              </div>
              <div className="feature_item_description_word">
                {lang === 0
                  ? contents.feature_rb.content[0]
                  : contents.feature_rb.content[1]}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
