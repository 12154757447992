import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { GlobalOutlined } from "@ant-design/icons";
import "./Header.css";

const changeLang = function () {
  let pathname = window.location.pathname;
  let isEn = pathname.endsWith("_en");
  let toUrl;
  if (isEn) {
    toUrl = pathname.replace(/_en$/i, "_cn");
  } else {
    toUrl = pathname.endsWith("/")
      ? "./home_en"
      : pathname.replace(/_cn$/i, "_en");
  }
  if (toUrl === "/home_cn") {
    toUrl = "/";
  }
  console.log("toURL: ", toUrl);
  window.location.href = toUrl;
};

export default function Header(props) {
  const { lang } = props;
  const containerRef = useRef(null);
  const [showNav, setShowNav] = useState(1); //1显示 2不显示
  const [langShow,setLangShow] = useState(true);//默认显示
  const changeNavShow = (e) => {
    e.stopPropagation();
    if (showNav === 1) {
      setShowNav(2);
    } else {
      setShowNav(1);
    }
  };
  // const handleClickOutside = (event) => {
  //   event.stopPropagation();
  //   if (containerRef.current && !containerRef.current.contains(event.target)) {
  //     console.log("Clicked outside");
  //     // 处理点击空白处的逻辑
  //     setShowNav(2);
  //   }
  // };
  useEffect(() => {
    console.log(window.location)
    let pathName = window.location.pathname;
    if(pathName === '/news_detail_cn' || pathName === '/news_detail_en'){
      setLangShow(false);
    }
    if (isMobileDevice()) {
      console.log("移动")     
      setShowNav(2);
      // document.addEventListener("mousedown", handleClickOutside);
      // return () => {
      //   document.removeEventListener("mousedown", handleClickOutside);
      // };
    }

 
   
  }, []);
  return (
    <div className="header_container">
      {/* 上侧LOGO及导航栏位置，其中导航栏通过id选择器监听滚动事件实现变色样式 */}
      <div className="header_topRow" id="header_topRow">
        <div className="header_rowContent">
          {/* LOGO及公司名称 */}
          <div className="header_left">
            <img className="header_logo" src="images/logo.png" alt="" />
            <br />
            <span className="header_span" id="header_span">
              上海林炎工贸有限公司
            </span>
          </div>

          {/* 导航栏 */}

          <div className="header_navi">
            {/* 移动端语言切换按钮 */}
           {langShow&&<div className="changeLangBtn" onClick={changeLang}>
              <GlobalOutlined style={{ fontSize: "26px", color: "#00f004" }} />
            </div>} 
            {/* 移动端导航按钮 */}
            <img
              src="images/menuicon.png"
              alt="menu"
              className="header_nav_mobile_btn"
              onClick={changeNavShow}
            />
         
              <ul ref={containerRef} style={{display:showNav ===1 ?'flex':'none'}}>
                <li>
                  <NavLink
                    className={dynamicClass}
                    to={"/" + (lang === 0 ? "" : "home_en")}
                  >
                    {lang === 0 ? "首页" : "Home"}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={dynamicClass}
                    to={"/cultures" + (lang === 0 ? "_cn" : "_en")}
                  >
                    {lang === 0 ? "文化与成就" : "Culture"}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={dynamicClass}
                    to={"/news" + (lang === 0 ? "_cn" : "_en")}
                  >
                    {lang === 0 ? "新闻与讯息" : "Information"}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={dynamicClass}
                    to={"/products" + (lang === 0 ? "_cn" : "_en")}
                  >
                    {lang === 0 ? "业务与产品" : "Products"}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={dynamicClass}
                    to={"/contacts" + (lang === 0 ? "_cn" : "_en")}
                  >
                    {lang === 0 ? "联系方式" : "Contacts"}
                  </NavLink>
                </li>
                {langShow&&<li onClick={changeLang} style={{ cursor: "pointer" }}>
                  <GlobalOutlined
                    style={{ fontSize: "26px", color: "#00f004" }}
                  />
                </li>}
                
              </ul>
        
          </div>
        </div>
      </div>
      {/* 下侧banner展示，其中rowBanner用于临时增加60px高度来不足banner图片高度的不足 */}
      <div className="header_rowBanner">
        <div className="header_bannerRoof"></div>
        <img src="images/banner2.png" alt="" />
      </div>
    </div>
  );
}
//判断设备是pc还是移动端
if (isMobileDevice()) {
  console.log("移动设备");
  
  window.addEventListener("scroll", () => {
    var header = document.getElementById("header_topRow");
    var span = document.getElementById("header_span");
    header.classList.toggle("header_topRowScrolled", window.scrollY > 48);
    span.classList.toggle("header_spanScrolled", window.scrollY > 48);
  });
} else {
  console.log("PC设备");
  //添加windows滚动事件监听，向下滚动超过60单位后，将header样式做动画变更
  window.addEventListener("scroll", () => {
    var header = document.getElementById("header_topRow");
    var span = document.getElementById("header_span");
    header.classList.toggle("header_topRowScrolled", window.scrollY > 60);
    span.classList.toggle("header_spanScrolled", window.scrollY > 60);
  });
}

// 工具函数，点击导航链接后，用CSS样式来标记当前Active的页面
function dynamicClass(isAct) {
  return isAct.isActive ? "header_naviAct header_naviItem" : "header_naviItem";
}
//工具函数 判断设备是pc还是移动
function isMobileDevice() {
  return /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}
//判断是不是ipad
// function isiPadDevice() {
//   return navigator.userAgent.match(/iPad/i) !== null;
// }